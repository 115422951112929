import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { AuthGuard } from '../app/_helpers/auth.guard';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './_services/authentication.service';
import { GatewayResolver } from './_services/gateway.resolver';
import { FilterComponent } from './components/filter/filter.component';
const name = environment.envVar.NAME;

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'buildings' },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('./pages/dashboard/dashboard.component').then(
            m => m.DashboardComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Dashboard`,
      },
      {
        path: 'buildings/:buildingId',
        loadComponent: () =>
          import('./pages/building-report/building-report.component').then(
            m => m.BuildingReportComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Building Details`,
      },
      {
        path: 'buildings',
        loadComponent: () =>
          import('./pages/buildings/buildings.component').then(
            m => m.BuildingsComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Buildings`,
      },
      {
        path: 'assets',
        loadComponent: () =>
          import('./pages/assets/assets.component').then(
            m => m.AssetsComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Assets`,
      },
      {
        path: 'assets/:assetId',
        loadComponent: () =>
          import('./pages/asset-report/asset-report.component'),
        canActivate: [AuthGuard],
        title: `${name} - Asset Details`,
      },
      {
        path: 'devices/:deviceId',
        loadComponent: () =>
          import('./pages/device-detail/device-detail.component').then(
            m => m.DeviceDetailComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Device Details`,
      },
      {
        path: 'rules',
        loadComponent: () =>
          import('./pages/rules/rules.component').then(m => m.RulesComponent),
        canActivate: [AuthGuard],
        title: `${name} - Rules`,
      },
      {
        path: 'schedules',
        loadComponent: () =>
          import(
            './pages/scheduled-flushing/scheduled-flushing.component'
          ).then(m => m.ScheduledFlushingComponent),
        canActivate: [AuthGuard],
        title: `${name} - Scheduled Flushing`,
      },
      {
        path: 'rules/:ruleId',
        loadComponent: () =>
          import('./pages/rule-detail/rule-detail.component').then(
            m => m.RuleDetailComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Rule Detail`,
      },
      {
        path: 'schedules/:scheduleId',
        loadComponent: () =>
          import('./pages/schedule-detail/schedule-detail.component').then(
            m => m.ScheduleDetailComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Schedule Detail`,
      },
      {
        path: 'users',
        loadComponent: () =>
          import('./pages/users/users.component').then(m => m.UsersComponent),
        canActivate: [AuthGuard],
        title: `${name} - Users`,
      },
      {
        path: 'users/:userId',
        loadComponent: () =>
          import('./pages/user-detail/user-detail.component').then(
            m => m.UserDetailComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - User Details`,
      },
      {
        path: 'settings',
        loadComponent: () =>
          import('./pages/settings/settings.component').then(
            m => m.SettingsComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Settings`,
      },
      {
        path: 'not-allowed',
        loadComponent: () =>
          import('./pages/no-permission/no-permission.component').then(
            m => m.PermissionComponent
          ),
        title: `${name} - Not Allowed`,
      },
      {
        path: 'reports',
        loadComponent: () =>
          import('./pages/reports/reports.component').then(
            m => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Reports`,
      },
      {
        path: 'reports/:reportId',
        loadComponent: () =>
          import('./pages/report-detail/report-detail.component').then(
            m => m.ReportDetailComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Report Detail`,
      },
      {
        path: 'sites/:siteId',
        loadComponent: () =>
          import('./pages/site-detail/site-detail.component').then(
            m => m.SiteDetailComponent
          ),
        canActivate: [AuthGuard],
        title: `${name} - Site Detail`,
      },
      {
        path: 'gateways',
        loadChildren: () =>
          import('@semvar/gateways').then(m => m.GatewaysModule),
        canActivate: [AuthGuard],
        title: `${name} - Gateways`,
        resolve: {
          data: GatewayResolver,
        },
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/auth-layout/auth-layout.module').then(
            m => m.AuthLayoutModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'buildings',
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

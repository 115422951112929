import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { BrowserModule } from '@angular/platform-browser';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ComponentsModule } from './components/components.module';

import { AppRoutingModule } from './app-routing.module';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgChartsModule } from 'ng2-charts';
import { OrdinalPipe } from './application-pipes/ordinal.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';


@NgModule({
    declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
    bootstrap: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ComponentsModule,
        BsDropdownModule.forRoot(),
        AppRoutingModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
        }),
        CollapseModule.forRoot(),
        NgCircleProgressModule.forRoot({
            outerStrokeWidth: 20,
            innerStrokeWidth: 20,
            innerStrokeColor: '#f8f9fe',
            animationDuration: 600,
        }),
        AppRoutingModule,
        ModalModule,
        LoadingBarHttpClientModule,
        NgChartsModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        BsModalService,
        OrdinalPipe,
        provideHttpClient(withInterceptorsFromDi()),
        providePrimeNG({
            theme: {
                preset: Aura,
                options: {
                    darkModeSelector: 'light'
                }
            }
        })
    ]
})
export class AppModule { }

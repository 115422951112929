<!-- <div class="bg-primary" style="min-height: 4px">
    <p-progressBar
      *ngIf="loader"
      mode="indeterminate"
      [style]="{ height: '4px' }"></p-progressBar>
</div> -->
<ngx-loading-bar
  [includeSpinner]="false"
  [height]="'4px'"
  [color]="'#5ccb56'"></ngx-loading-bar>
<router-outlet></router-outlet>

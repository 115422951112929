import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { GeneralService } from './general.service';
import { FilterComponent } from '../components/filter/filter.component';

export const GatewayResolver: ResolveFn<any> = (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const generalService = inject(GeneralService);

  generalService.setBreadcrumbs([{ title: 'All Gateways', isPage: true }]);
  return {
    authenticationService: authenticationService.currentAccountValue,
    environment: environment,
    filterComponent: FilterComponent,
  };
};

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { GeneralService } from '../_services/general.service';
import { ToastService } from '../_services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private toast: ToastService,
    private generalService: GeneralService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        this.authenticationService.setLoading(false);
        this.generalService.setBtnLoader(false);
        const error = err?.error?.message || err?.statusText;

        if (err?.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          // location.reload();
          this.toast.show('Error', error, 'danger');
          return throwError(() => new Error(error));
        }

        const errorMessage = error || 'Unknown error';
        this.toast.show('Error', errorMessage, 'danger');
        return throwError(() => new Error(error));
      })
    );
  }
}

import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, Injectable, Inject, Optional, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { Subject, of, timer, combineLatest, Observable } from 'rxjs';
import { take, map, tap, startWith, switchMap, shareReplay } from 'rxjs/operators';
class LoadingBarState {
  constructor(config = {}) {
    this.config = config;
    this.state = {
      action: null,
      value: 0,
      initialValue: 0
    };
    this.requests = null;
    this.disabled = false;
    this.stream$ = new Subject();
    this._value$ = null;
    this.timer$ = s => {
      let state$ = of(s);
      switch (s.action) {
        case 'start':
        case 'increment':
        case 'set':
          {
            if (s.action === 'start' && this.config.latencyThreshold === 0 && s.value === 0) {
              s.value = s.initialValue;
            }
            if (this.requests > 0) {
              state$ = timer(this.config.latencyThreshold, 250).pipe(map(t => ({
                ...s,
                value: t === 0 ? this.state.value || s.initialValue : this._increment()
              })));
            }
            break;
          }
        case 'complete':
        case 'stop':
          {
            // Attempt to aggregate any start/complete calls within 500ms:
            state$ = s.value === 0 ? of({
              ...s
            }) : timer(0, 500).pipe(take(2), map(t => ({
              value: t === 0 ? 100 : 0
            })));
            break;
          }
      }
      return state$.pipe(map(next => ({
        ...next,
        action: 'set'
      })), tap(next => this.next(next, false)));
    };
    this.config = {
      latencyThreshold: 0,
      ...config
    };
  }
  get value$() {
    if (this._value$) {
      return this._value$;
    }
    return this._value$ = this.stream$.pipe(startWith(this.state), switchMap(s => this.timer$(s)), shareReplay(), map(s => s.value));
  }
  start(initialValue = 2) {
    if (this.disabled) {
      return;
    }
    this.next({
      action: 'start',
      initialValue
    });
  }
  stop() {
    this.next({
      action: 'stop'
    });
  }
  complete() {
    this.next({
      action: 'complete'
    });
  }
  disable() {
    this.disabled = true;
  }
  set(value) {
    this.next({
      action: 'set',
      value
    });
  }
  increment(value = 0) {
    this.next({
      action: 'increment',
      value
    });
  }
  next(state, emitEvent = true) {
    switch (state.action) {
      case 'start':
        this.requests = (this.requests || 0) + 1;
        break;
      case 'complete':
        this.requests = (this.requests || 1) - 1;
        if (this.requests > 0) {
          return;
        }
        break;
      case 'stop':
        this.requests = 0;
        break;
      case 'increment':
        state.value = this._increment(state.value);
        break;
    }
    this.state = {
      ...this.state,
      action: null,
      ...state
    };
    if (emitEvent) {
      this.stream$.next(this.state);
    }
  }
  _increment(rnd = 0) {
    const stat = this.state.value;
    if (stat >= 99) {
      rnd = 0;
    }
    if (rnd === 0) {
      if (stat >= 0 && stat < 25) {
        // Start out between 3 - 6% increments
        rnd = Math.random() * (5 - 3 + 1) + 3;
      } else if (stat >= 25 && stat < 65) {
        // increment between 0 - 3%
        rnd = Math.random() * 3;
      } else if (stat >= 65 && stat < 90) {
        // increment between 0 - 2%
        rnd = Math.random() * 2;
      } else if (stat >= 90 && stat < 99) {
        // finally, increment it .5 %
        rnd = 0.5;
      } else {
        // after 99%, don't increment:
        rnd = 0;
      }
    }
    return rnd + stat;
  }
}
const LOADING_BAR_CONFIG = new InjectionToken('LOADING_BAR_CONFIG');
class LoadingBarService {
  constructor(platformId, config = {}, zone) {
    this.platformId = platformId;
    this.config = config;
    this.zone = zone;
    this.refs = {};
    this.streams$ = new Subject();
    this.value$ = this.streams$.pipe(startWith(null), switchMap(() => combineLatest(Object.keys(this.refs).map(s => this.refs[s].value$))), runInZone(this.zone), map(v => Math.max(0, ...v)));
  }
  /** @deprecated use `value$` instead. */
  get progress$() {
    return this.value$;
  }
  /** @deprecated use `useRef` instead. */
  start(initialValue = 2) {
    this.useRef().start(initialValue);
  }
  /** @deprecated use `useRef` instead. */
  set(value) {
    this.useRef().set(value);
  }
  /** @deprecated use `useRef` instead. */
  increment(value) {
    this.useRef().increment(value);
  }
  /** @deprecated use `useRef` instead. */
  complete() {
    this.useRef().complete();
  }
  /** @deprecated use `useRef` instead. */
  stop() {
    this.useRef().stop();
  }
  useRef(id = 'default') {
    if (!this.refs[id]) {
      this.refs[id] = new LoadingBarState(this.config);
      this.streams$.next();
      if (!isPlatformBrowser(this.platformId)) {
        this.refs[id].disable();
      }
    }
    return this.refs[id];
  }
}
LoadingBarService.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "13.1.1",
  ngImport: i0,
  type: LoadingBarService,
  deps: [{
    token: PLATFORM_ID
  }, {
    token: LOADING_BAR_CONFIG,
    optional: true
  }, {
    token: i0.NgZone,
    optional: true
  }],
  target: i0.ɵɵFactoryTarget.Injectable
});
LoadingBarService.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "13.1.1",
  ngImport: i0,
  type: LoadingBarService,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "13.1.1",
  ngImport: i0,
  type: LoadingBarService,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: Object,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [LOADING_BAR_CONFIG]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Optional
      }]
    }];
  }
});
// https://stackoverflow.com/a/57452361/1406096
function runInZone(zone) {
  if (!zone) {
    return source => source;
  }
  return source => new Observable(observer => source.subscribe(value => zone.run(() => observer.next(value)), e => zone.run(() => observer.error(e)), () => zone.run(() => observer.complete())));
}
class LoadingBarComponent {
  constructor(loader) {
    this.loader = loader;
    this.includeSpinner = true;
    this.includeBar = true;
    this.fixed = true;
    this.color = '#29d';
  }
  get value$() {
    return this.ref ? this.loader.useRef(this.ref).value$ : this.loader.value$;
  }
}
LoadingBarComponent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "13.1.1",
  ngImport: i0,
  type: LoadingBarComponent,
  deps: [{
    token: LoadingBarService
  }],
  target: i0.ɵɵFactoryTarget.Component
});
LoadingBarComponent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "13.1.1",
  type: LoadingBarComponent,
  selector: "ngx-loading-bar",
  inputs: {
    includeSpinner: "includeSpinner",
    includeBar: "includeBar",
    fixed: "fixed",
    color: "color",
    value: "value",
    ref: "ref",
    height: "height",
    diameter: "diameter"
  },
  host: {
    properties: {
      "attr.fixed": "fixed",
      "style.color": "color"
    }
  },
  ngImport: i0,
  template: `
    <ng-container *ngIf="value != null ? value : (value$ | async) as progress">
      <div *ngIf="includeSpinner" class="ngx-spinner">
        <div [style.width]="diameter" [style.height]="diameter" class="ngx-spinner-icon"></div>
      </div>
      <div
        *ngIf="includeBar"
        class="ngx-bar"
        [style.background]="color"
        [style.height]="height"
        [style.width]="progress + '%'"
      ></div>
    </ng-container>
  `,
  isInline: true,
  styles: [":host{position:relative;display:block;pointer-events:none}:host .ngx-spinner{transition:.35s linear all;display:block;position:absolute;top:5px;left:0px}:host .ngx-spinner .ngx-spinner-icon{width:14px;height:14px;border:solid 2px transparent;border-top-color:inherit;border-left-color:inherit;border-radius:50%;-webkit-animation:loading-bar-spinner .4s linear infinite;animation:loading-bar-spinner .4s linear infinite}:host .ngx-bar{transition:width .35s;position:absolute;top:0;left:0;width:100%;height:2px;border-bottom-right-radius:1px;border-top-right-radius:1px}[dir=rtl] :host .ngx-bar{right:0;left:unset}:host[fixed=true]{z-index:10002}:host[fixed=true] .ngx-bar{position:fixed}:host[fixed=true] .ngx-spinner{position:fixed;top:10px;left:10px}[dir=rtl] :host[fixed=true] .ngx-spinner{right:10px;left:unset}@-webkit-keyframes loading-bar-spinner{0%{transform:rotate(0)}to{transform:rotate(360deg)}}@keyframes loading-bar-spinner{0%{transform:rotate(0)}to{transform:rotate(360deg)}}\n"],
  directives: [{
    type: i2.NgIf,
    selector: "[ngIf]",
    inputs: ["ngIf", "ngIfThen", "ngIfElse"]
  }],
  pipes: {
    "async": i2.AsyncPipe
  },
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "13.1.1",
  ngImport: i0,
  type: LoadingBarComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ngx-loading-bar',
      template: `
    <ng-container *ngIf="value != null ? value : (value$ | async) as progress">
      <div *ngIf="includeSpinner" class="ngx-spinner">
        <div [style.width]="diameter" [style.height]="diameter" class="ngx-spinner-icon"></div>
      </div>
      <div
        *ngIf="includeBar"
        class="ngx-bar"
        [style.background]="color"
        [style.height]="height"
        [style.width]="progress + '%'"
      ></div>
    </ng-container>
  `,
      preserveWhitespaces: false,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.Emulated,
      host: {
        '[attr.fixed]': 'fixed',
        '[style.color]': 'color'
      },
      styles: [":host{position:relative;display:block;pointer-events:none}:host .ngx-spinner{transition:.35s linear all;display:block;position:absolute;top:5px;left:0px}:host .ngx-spinner .ngx-spinner-icon{width:14px;height:14px;border:solid 2px transparent;border-top-color:inherit;border-left-color:inherit;border-radius:50%;-webkit-animation:loading-bar-spinner .4s linear infinite;animation:loading-bar-spinner .4s linear infinite}:host .ngx-bar{transition:width .35s;position:absolute;top:0;left:0;width:100%;height:2px;border-bottom-right-radius:1px;border-top-right-radius:1px}[dir=rtl] :host .ngx-bar{right:0;left:unset}:host[fixed=true]{z-index:10002}:host[fixed=true] .ngx-bar{position:fixed}:host[fixed=true] .ngx-spinner{position:fixed;top:10px;left:10px}[dir=rtl] :host[fixed=true] .ngx-spinner{right:10px;left:unset}@-webkit-keyframes loading-bar-spinner{0%{transform:rotate(0)}to{transform:rotate(360deg)}}@keyframes loading-bar-spinner{0%{transform:rotate(0)}to{transform:rotate(360deg)}}\n"]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: LoadingBarService
    }];
  },
  propDecorators: {
    includeSpinner: [{
      type: Input
    }],
    includeBar: [{
      type: Input
    }],
    fixed: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    ref: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    diameter: [{
      type: Input
    }]
  }
});
class LoadingBarModule {}
LoadingBarModule.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "13.1.1",
  ngImport: i0,
  type: LoadingBarModule,
  deps: [],
  target: i0.ɵɵFactoryTarget.NgModule
});
LoadingBarModule.ɵmod = i0.ɵɵngDeclareNgModule({
  minVersion: "12.0.0",
  version: "13.1.1",
  ngImport: i0,
  type: LoadingBarModule,
  declarations: [LoadingBarComponent],
  imports: [CommonModule],
  exports: [LoadingBarComponent]
});
LoadingBarModule.ɵinj = i0.ɵɵngDeclareInjector({
  minVersion: "12.0.0",
  version: "13.1.1",
  ngImport: i0,
  type: LoadingBarModule,
  imports: [[CommonModule]]
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "13.1.1",
  ngImport: i0,
  type: LoadingBarModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [LoadingBarComponent],
      exports: [LoadingBarComponent]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { LOADING_BAR_CONFIG, LoadingBarComponent, LoadingBarModule, LoadingBarService };

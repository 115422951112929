<nav
  id="navbar-main"
  class="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light">
  <div class="container">
    <a class="navbar-brand mt-6 img-center" [routerLink]="['/']">
      <img [src]="logo" alt="Logo" style="width: 280px; height: auto" />
    </a>
  </div>
</nav>
<router-outlet></router-outlet>
<footer class="py-5">
  <div class="container">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6"></div>
      <div class="col-xl-6"></div>
    </div>
  </div>
</footer>
